@use "~ag-grid-community/styles" as ag;

@tailwind base;
@tailwind components;
@tailwind utilities;

@include ag.grid-styles((
    themes: (
        alpine: (),
        alpine-compact: (
            extend-theme: alpine,
            grid-size: 4px,
            icon-size: 10px,
            font-size: 12px,
        ),
    ),
    font-family: 'Work Sans',
));

.ag-center-header .ag-header-cell-label, .ag-header-group-cell {
    justify-content: center;
}
