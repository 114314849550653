.subsectionRow {
  display: contents;
}

.subsectionCell {
  max-width: 16rem;
}

.subsectionRow:hover > span {
  @apply bg-gray-100;
}
